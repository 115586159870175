






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ProgressBar from 'vuejs-progress-bar'
import { ref } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { useGetVideoClipsById } from '@/hooks/audio'
import { formatTimeAgo } from '@/utils/time'
import { uploadStatus } from '../Publish/publish'
import { Action, Getter } from 'vuex-class'
import AdoriService from '@/services/adori'

@Component({
  components: {
    ProgressBar,
  },
  setup({ exportId, youtubeSignInStatus }: any) {
    const interval: any = ref(false)
    const queryClient = useQueryClient()
    const { data: exportData, isLoading: exportLoading } = useGetVideoClipsById(exportId, true, interval)
    return {
      exportData,
      exportLoading,
      interval,
      queryClient,
    }
  },
})
export default class ExportedItem extends Vue {
  @Prop() exportId!: any

  @Getter networkId!: string

  @Action pushNotification!: any

  exportData!: any
  exportLoading!: any
  interval!: any
  queryClient!: QueryClient
  downloadLoader = false

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: true,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      width: 200,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }

  get exportImage() {
    return this.exportData && this.exportData.posterImage ? this.exportData.posterImage.thumbnailURL : null
  }

  get exportDate() {
    return formatTimeAgo(this.exportData.createdOn)
  }

  get showProgressBar() {
    if (this.exportData.status === uploadStatus.FINISHED) return false
    else if (this.exportData.status == uploadStatus.QUEUED || this.exportData.status == uploadStatus.STARTED)
      return true
    return false
  }

  get progressBarValue() {
    return Math.ceil(this.exportData.progress)
  }

  @Watch('exportData')
  watchClip() {
    this.exportProcess()
  }

  async downloadVideo() {
    this.downloadLoader = true
    const params = {
      network_id: this.networkId,
      task_id: this.exportData.id,
    }
    let data!: any
    try {
      data = await AdoriService.youtube_download(params)
      if (!data.url) {
        this.pushNotification({ text: 'No URL available', type: 'ERROR' })
        return
      }
    } catch (error) {
      this.downloadLoader = false
    }

    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
    this.downloadLoader = false
  }

  exportProcess() {
    if (this.exportData.status === uploadStatus.FINISHED) {
      if (this.interval !== false) this.downloadVideo()
      this.interval = false
    } else if (this.exportData.status == uploadStatus.QUEUED || this.exportData.status == uploadStatus.STARTED) {
      this.interval = 7000
    }
  }

  mounted() {
    console.log(this.exportData)
    this.exportProcess()
  }
}
