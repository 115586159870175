var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            {
              staticClass: "limiter scrollbar relative",
              staticStyle: { width: "768px" },
            },
            [
              _c("a", {
                ref: "videoDownloadRef",
                staticClass: "dn",
                attrs: { download: "" },
              }),
              _c(
                "div",
                { staticClass: "flex justify-between items-center" },
                [
                  _c("TheTitle", {
                    attrs: { icon: "ondemand_video", title: "Video preview" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "unselectable pointer pl2 br2 ba b--adori-gray light-gray bg-dark-gray",
                      on: { click: _vm.openMenu },
                    },
                    [
                      _c(
                        "BasePopupMenu",
                        {
                          ref: "basePopupMenu",
                          staticClass: "dropdown flex items-center",
                          attrs: {
                            icon: "arrow_drop_down",
                            items: _vm.videoResolutionMenu,
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "mh1 material-icons white text-shadow",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.selectedOrientation.icon) + " "
                              ),
                            ]
                          ),
                          _c("span", {}, [
                            _vm._v(_vm._s(_vm.selectedOrientation.text)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex justify-center items-center" }, [
                _vm.isPlayerLoading
                  ? _c("div", {
                      staticClass: "bg-adori-light-gray skeleton w-100",
                      staticStyle: { height: "389px" },
                    })
                  : _vm._e(),
                _c("iframe", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isPlayerLoading,
                      expression: "!isPlayerLoading",
                    },
                  ],
                  ref: "iframe",
                  staticClass: "b--adori-light-gray br2",
                  attrs: {
                    frameborder: "0",
                    scrolling: "no",
                    title: "Adori Player",
                    width: _vm.selectedOrientation.iframeWidth,
                    height: "389px",
                    src: "https://player.dev.adoriai.com",
                    allowfullscreen: "",
                  },
                  on: { load: _vm.iframeLoad },
                }),
              ]),
              !_vm.showProgressBar
                ? _c(
                    "div",
                    { staticClass: "flex justify-between mt3" },
                    [
                      _c("base-button-border", {
                        attrs: { text: "Edit", onClick: _vm.handleEdit },
                      }),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _vm.exportsList.length
                            ? _c("base-button-border", {
                                staticClass: "mr2",
                                attrs: {
                                  text: "Recent Exports",
                                  onClick: function () {
                                    _vm.showExports = true
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("base-button-red", {
                            attrs: {
                              text: "Export video",
                              onClick: function () {
                                _vm.showDownloadSetting = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showProgressBar
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-between items-center w-100 b--adori-light-gray ba mt3 pa4 br3",
                    },
                    [
                      _c("ProgressBar", {
                        attrs: {
                          options: _vm.options,
                          value: _vm.progressBarValue,
                          max: 100,
                        },
                      }),
                      _c(
                        "div",
                        [
                          _c("BaseButtonRed", {
                            staticClass: "youtube-link",
                            attrs: {
                              text: "Download",
                              loading: !_vm.isDownloadReady,
                              icon: "download",
                              disabled: !_vm.isDownloadReady,
                              onClick: _vm.downloadVideo,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showProgressBar
                ? _c(
                    "div",
                    { staticClass: "bg-adori-medium-gray mt4 pa3 br2 gray" },
                    [
                      _vm._v(
                        "\n        Note: The preview player offers a quick representation and may show blank frames, which will not appear in the\n        final video.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm.showDownloadSetting
        ? _c("ModalVideoSettings", {
            attrs: { isRendering: _vm.isRendering },
            on: {
              closeModal: function ($event) {
                _vm.showDownloadSetting = false
              },
              render: _vm.handleRender,
            },
          })
        : _vm._e(),
      _vm.showExports
        ? _c("ModalExports", {
            on: {
              closeModal: function ($event) {
                _vm.showExports = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }