






























































































import AdoriService from '@/services/adori'
import { Component, Vue, Watch } from 'vue-property-decorator'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import { computed, SetupContext } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import { audioQueryKeys, useGetAudioTrack, useGetVideoClipsByTrackId } from '@/hooks/audio'
import { tagQueryKeys, useGetTrackTags } from '@/hooks/tag'
import { useGetSubtitles } from '@/hooks/subtitle'
import ModalVideoSettings from '@/components/Modals/ModalVideoSettings.vue'
import ModalExports from '@/components/Modals/ModalExports.vue'
import ProgressBar from 'vuejs-progress-bar'
import { Action, Getter } from 'vuex-class'
import { billingQueryKeys } from '@/hooks/billing'
import get from 'lodash.get'

@Component({
  components: {
    TheHeader,
    TheSideNavigation,
    TheTitle,
    ModalVideoSettings,
    ModalExports,
    ProgressBar,
  },

  setup(_, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const audioTrackId: any = computed(() => root.$route.params.id)
    const isTrackEnabled = computed(() => !!audioTrackId.value)
    const { data: audioData, isFetching: isAudioTrackLoading } = useGetAudioTrack(audioTrackId.value)
    const { data: exportsData } = useGetVideoClipsByTrackId(audioTrackId)
    const { data: trackTags, isFetching: isTrackTagsLoading } = useGetTrackTags(audioTrackId, {
      enabled: isTrackEnabled,
    })
    const { data: subtitleData, isLoading: isLoadingSubtitles } = useGetSubtitles(audioTrackId)

    return {
      queryClient,
      audioData,
      isAudioTrackLoading,
      trackTags,
      isTrackTagsLoading,
      subtitleData,
      isLoadingSubtitles,
      exportsData,
    }
  },
})
export default class ViewVideoPreview extends Vue {
  @Getter networkId!: string

  @Action pushNotification!: any
  @Action youtubeUpload!: any
  @Action youtubeUploadStatus!: any
  @Action updateAudioTag!: any

  queryClient!: QueryClient
  audioData!: any
  isAudioTrackLoading!: boolean
  trackTags!: any
  isTrackTagsLoading!: boolean
  subtitleData!: any
  isLoadingSubtitles!: boolean
  audioEditor = false
  isPlayerLoading = true
  playerObj!: any
  isIframeLoaded!: any
  showDownloadSetting = false
  showExports = false
  progressBarValue = 0
  showProgressBar = false
  isRendering = false
  interval!: any
  isDownloadReady = false
  selectedOrientation = {
    text: 'Landscape (16:9)',
    icon: 'stay_primary_landscape',
    width: 1280,
    height: 720,
    iframeWidth: '100%',
    orientation: 'LANDSCAPE',
  }
  exportsData!: any

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 12,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: false,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 20,
      width: 450,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }

  async mounted() {
    this.onDataChange()
  }

  get exportsList() {
    return get(this.exportsData, 'data', [])
  }

  get videoResolutionMenu() {
    let menu = [
      {
        name: 'Landscape (16:9)',
        icon: 'stay_primary_landscape',
        onClick: () => {
          this.selectedOrientation = {
            text: 'Landscape (16:9)',
            icon: 'stay_primary_landscape',
            width: 1280,
            height: 720,
            iframeWidth: '100%',
            orientation: 'LANDSCAPE',
          }
          this.onDataChange()
        },
      },
      {
        name: 'Vertical (9:16)',
        icon: 'stay_primary_portrait',
        onClick: () => {
          this.selectedOrientation = {
            text: 'Vertical (9:16)',
            icon: 'stay_primary_portrait',
            width: 720,
            height: 1280,
            iframeWidth: '219px',
            orientation: 'PORTRAIT',
          }
          this.onDataChange()
        },
      },
      {
        name: 'Square (1:1)',
        icon: 'crop_square',
        onClick: () => {
          this.selectedOrientation = {
            text: 'Square (1:1)',
            icon: 'crop_square',
            width: 720,
            height: 720,
            iframeWidth: '389px',
            orientation: 'SQUARE',
          }
          this.onDataChange()
        },
      },
    ]

    return menu
  }

  get isAllDataLoaded() {
    return (
      !this.isPlayerLoading &&
      !this.isAudioTrackLoading &&
      !this.isTrackTagsLoading &&
      !this.isLoadingSubtitles &&
      this.audioData &&
      this.trackTags &&
      this.subtitleData
    )
  }

  @Watch('isAllDataLoaded')
  handleDataLoaded() {
    if (this.isAllDataLoaded) {
      this.createPlayerObj()
    }
  }

  @Watch('audioData', { deep: true })
  @Watch('trackTags', { deep: true })
  @Watch('subtitleData', { deep: true })
  @Watch('selectedOrientation', { deep: true })
  onDataChange() {
    if (this.isAllDataLoaded) {
      this.createPlayerObj()
    }
  }

  createPlayerObj() {
    this.playerObj = {
      audioTrack: this.audioData,
      mediaData: this.trackTags,
      transcript: this.subtitleData.textBlocks,
      width: this.selectedOrientation.width,
      height: this.selectedOrientation.height,
    }
    this.sendDataToIframe()
  }

  sendDataToIframe() {
    const iframe: any = this.$refs.iframe
    const data = this.playerObj
    iframe.contentWindow.postMessage(data, 'https://player.dev.adoriai.com') // Ensure the target origin matches the iframe's origin
  }

  handleEdit() {
    this.$router.push('/audio-editor?uid=' + this.$route.params.id)
  }

  openMenu() {
    const menu = this.$refs.basePopupMenu as any
    menu.toggleMenu() // menu.open = true
  }

  iframeLoad() {
    this.isPlayerLoading = false
  }

  getResolution(resolution: 480 | 720 | 1080 | 1440 | 2160, orientation: 'SQUARE' | 'LANDSCAPE' | 'PORTRAIT') {
    const resolutions = {
      2160: { width: 3840, height: 2160 },
      1440: { width: 2560, height: 1440 },
      1080: { width: 1920, height: 1080 },
      720: { width: 1280, height: 720 },
      480: { width: 854, height: 480 },
    }

    const res = resolutions[resolution]

    if (!res) {
      throw new Error('Invalid resolution input')
    }

    const { width, height } = res

    switch (orientation) {
      case 'SQUARE':
        return { width: Math.min(width, height), height: Math.min(width, height) }
      case 'PORTRAIT':
        return { width: Math.min(width, height), height: Math.max(width, height) }
      case 'LANDSCAPE':
        return { width: Math.max(width, height), height: Math.min(width, height) }
      default:
        throw new Error('Invalid orientation input')
    }
  }

  async handleRender(renderData: any) {
    const resolution = this.getResolution(
      renderData.resolution,
      this.selectedOrientation.orientation as 'SQUARE' | 'LANDSCAPE' | 'PORTRAIT'
    )
    if (this.trackTags.length) {
      const tagPayload: any = {
        id: this.trackTags[0].id,
        tagId: this.trackTags[0].tagId,
        offsetMillis: this.trackTags[0].offsetMillis,
        durationMillis: this.trackTags[0].durationMillis,
        transition: renderData.transition,
        fitToScreen: renderData.backgroundFit,
        applyEffectToAllTags: true,
      }

      await this.updateAudioTag({
        audioUid: this.audioData.uid,
        payload: tagPayload,
      })
      this.queryClient.invalidateQueries([tagQueryKeys.TRACK_TAGS])
    }

    let commonPayload: any = {
      trackUid: this.audioData.uid,
      categoryId: 24,
      privacy: 'public',
      title: this.audioData.name.substring(0, 99),
      width: resolution.width,
      height: resolution.height,
      startTimeSec: 0,
      endTimeSec: null,
      type: 'INTERACTIVE',
      posterImageId: this.audioData.imageId,
      publishToYoutube: false,
      burnSubtitle: renderData.subtitle,
      backgroundVideo: false,
    }

    const payload: any = {
      data: {
        ...commonPayload,
      },
      params: {
        network_id: this.networkId,
      },
    }

    let video!: any
    try {
      this.isRendering = true
      video = await this.youtubeUpload(payload)
      this.isRendering = false
      //   this.showProgressBar = true
    } catch (error) {
      this.isRendering = false
    }

    this.pushNotification({
      text: `Downloading in progress. Please wait.`,
      type: 'SUCCESS',
    })

    sessionStorage.setItem(this.audioData.uid, this.audioData.uid)

    this.queryClient.invalidateQueries(['TRACKVIDEOCLIPS', this.audioData.uid])
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
    this.queryClient.invalidateQueries(billingQueryKeys.BILLING_INVOICES)
    this.queryClient.invalidateQueries(billingQueryKeys.BILLING_USAGE)

    this.showDownloadSetting = false
    this.showExports = true

    // if (video.status === uploadStatus.QUEUED || video.status === uploadStatus.STARTED) {
    //   this.interval = setInterval(async () => {
    //     const getRes = await this.youtubeUploadStatus({
    //       network_id: this.networkId,
    //       task_id: video.id,
    //     })
    //     if (getRes.status === uploadStatus.QUEUED || getRes.status === uploadStatus.STARTED) {
    //       this.progressBarValue = Math.ceil(getRes.progress)
    //     } else if (getRes.status === uploadStatus.FINISHED) {
    //       this.progressBarValue = 100
    //       this.downloadVideoFile(getRes.id)
    //       clearInterval(this.interval)
    //     } else {
    //       clearInterval(this.interval)
    //     }
    //   }, 6000)
    // }
  }

  async downloadVideoFile(id: any) {
    this.isDownloadReady = true
    const params = {
      network_id: this.networkId,
      task_id: id,
    }

    const data: any = await AdoriService.youtube_download(params)
    if (!data.url) {
      this.pushNotification({ text: 'No URL available', type: 'ERROR' })
      return
    }

    if (this.$refs['videoDownloadRef']) {
      //@ts-ignore
      this.$refs['videoDownloadRef'].href = data.url
      const audioDownloadLink = this.$refs.videoDownloadRef as HTMLElement
      audioDownloadLink.click()
    }
  }

  async downloadVideo() {
    this.$emit('removeOnload')
    this.$gtag.event('download-video', {
      event_category: 'User Interaction',
      event_label: 'Download video',
    })

    const videoDownloadLink = this.$refs.videoDownloadRef as HTMLElement
    videoDownloadLink.click()
  }

  destroyed() {
    clearInterval(this.interval)
  }
}
