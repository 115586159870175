var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f4 light-gray fw5" }, [
          _vm._v("Recent Exports"),
        ]),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("div", { staticClass: "w-100 white mw-900" }, [
            _c(
              "div",
              _vm._l(_vm.exportsList, function (exportItem) {
                return _c("ExportedItem", {
                  key: exportItem.id,
                  attrs: { exportId: exportItem.id },
                })
              }),
              1
            ),
          ]),
          _vm.showPlans
            ? _c("ModalPlans", {
                attrs: { customModal: "" },
                on: {
                  closeModal: function ($event) {
                    _vm.showPlans = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }